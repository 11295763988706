import { graphql } from 'gatsby';
import { defineMessages } from 'gatsby-plugin-locale';
import React, { FC } from 'react';
import { Layout } from '../components/Layout';
import { HeroSection } from '../components/sections/HeroSection';
import { Tools } from '../components/sections/Tools';
import { SEO } from '../components/seo';
import { GatsbyPageProps } from '../declarations';
import { get } from '../utils';
import { IndexQuery } from './__generated__/IndexQuery';

const IndexPage: FC<{ data: IndexQuery } & GatsbyPageProps> = ({ data }) => {
	const home = get(data, 'home', 'data');
	const categories = get(data, 'categories', 'edges').map(({ node }) => node);
	const tools = get(data, 'tools', 'edges').map(({ node }) => node);

	return (
		<Layout>
			<SEO />
			<header>
				<HeroSection data={home} />
			</header>

			<Tools categoriesNodes={categories} toolsNodes={tools} />
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexQuery($locale: String!) {
		home: prismicHomepage(lang: { eq: $locale }) {
			data {
				header {
					text
				}
				header_description {
					text
					html
				}
			}
		}

		categories: allPrismicCategory(filter: { lang: { eq: $locale } }) {
			edges {
				node {
					uid
					data {
						name
					}
				}
			}
		}

		tools: allPrismicTool(filter: { lang: { eq: $locale } }) {
			edges {
				node {
					url
					data {
						name {
							text
						}
						categories{
							category {
								id
								uid
							}
						}
						short_description {
							text
						}
						is_available_for_free
						link_to_file {
							url
							raw
						}
					}
				}
			}
		}
	}
`;
