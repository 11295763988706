import React, { FC, useState, useCallback } from 'react';
import { defineMessages, useIntl } from 'gatsby-plugin-locale';
import { useAuthContext } from '../../hooks/useAuthContext';
import { IndexQuery_home_data } from '../../pages/__generated__/IndexQuery';
import { Link } from '../Link';
import { useClickOutside } from '../../hooks/useClickOutside';

interface Props {
	data: IndexQuery_home_data;
}

export const HeroSection: FC<Props> = ({ data }) => {
	const { formatMessage: f } = useIntl();
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

	const onOutsideClick = useCallback(() => setDropdownOpen(false), [setDropdownOpen]);

	const { ref } = useClickOutside(dropdownOpen, onOutsideClick);

	const { loggedIn, user, logout } = useAuthContext();

	const toggleDropdown = () => setDropdownOpen(value => !value);

	return (
		<>
			<div className="container mx-auto my-12 xl:my-24 px-10 md:px-0">
				<div className="flex flex-col flex-wrap z-20 relative">
					<h1 className="text-secondary text-2xl font-black md:text-3xl mb-6 max-w-lg">
						{data.header?.text}
					</h1>
					<div
						className="subheader text-secondary-600 text-sm md:text-base leading-relaxed max-w-2xl"
						dangerouslySetInnerHTML={{ __html: data.header_description?.html }}
					/>

					{!loggedIn && (
						<>
							<div className="mt-10">
								<Link to="/auth/login" color="primary">
									{f(messages.login)}
								</Link>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://intensio.be/over-ons"
									className="ml-4 text-secondary">
									{f(messages.whoAreWe)}
								</a>
							</div>
						</>
					)}
				</div>
			</div>
			<hr />

			{loggedIn && (
				<div className="flex flex-row text-secondary-600 mt-8 justify-end container mx-auto">
					<div ref={ref} className="relative">
						<button
							className="rounded-sm focus:outline-none flex flex-column items-center p-1"
							onClick={toggleDropdown}>
							<div>Welkom {user?.attributes?.given_name}</div>
							<i className="bx bx-chevron-down text-lg"></i>
						</button>
						{dropdownOpen && (
							<div className="bg-white shadow-md rounded-lg absolute right-0 text-sm border-gray-100 border py-1 w-full">
								<button
									className="block px-4 py-2 focus:outline-none"
									onClick={logout}>
									{f(messages.logout)}
								</button>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

// INTL

const messages = defineMessages({
	login: {
		id: 'heroSection.login',
		defaultMessage: 'Login',
	},
	whoAreWe: {
		id: 'heroSection.whoAreWe',
		defaultMessage: 'Over Intensio',
	},
	logout: {
		id: 'heroSection.logout',
		defaultMessage: 'Uitloggen',
	},
});
