import cn from 'classnames';
import { Link } from 'gatsby-plugin-locale';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'gatsby-plugin-locale';
import Masonry from 'react-masonry-css';
import { useMedia } from '../../hooks/useMedia';
import {
	IndexQuery_categories_edges_node,
	IndexQuery_tools_edges_node,
} from '../../pages/__generated__/IndexQuery';
import { get, sortByName } from '../../utils';
import { ConditionalWrap } from '../ConditionalWap';
import { useAuthContext } from '../../hooks/useAuthContext';

interface Props {
	categoriesNodes: IndexQuery_categories_edges_node[];
	toolsNodes: IndexQuery_tools_edges_node[];
}

export const Tools: FC<Props> = ({ categoriesNodes, toolsNodes }) => {
	const { formatMessage: f } = useIntl();
	const breakpoint = useMedia({ xs: 0, lg: 1024 }, 'xs');
	const [isCategoryFilterOpen, setCategoryFilterOpen] = useState(breakpoint !== 'xs');
	const [selectedCategory, selectCategory] = useState<string | null>();
	const { loggedIn } = useAuthContext();

	useEffect(() => {
		if (breakpoint === 'xs' && isCategoryFilterOpen) {
			setCategoryFilterOpen(false);
		} else if (breakpoint === 'lg') {
			setCategoryFilterOpen(true);
		}
	}, [breakpoint]);

	const categories = categoriesNodes
		.map(node => ({
			name: node.data?.name as string,
			id: node.uid,
		}))
		.sort(sortByName);

	const toolsToShow = useMemo(() => {
		return toolsNodes
			.map(node => ({
				name: node.data?.name?.text as string,
				categories: node.data?.categories?.map(c => c?.category?.uid) ?? [],
				description: node.data?.short_description?.text,
				url: node.url as string,
				linkToFile: node.data?.link_to_file,
				isAvailableForFree: node.data?.is_available_for_free,
			}))
			.sort(sortByName)
			.filter(tool => {
				let shouldShow = true;

				if (selectedCategory) {
					shouldShow = shouldShow && tool.categories.includes(selectedCategory);
				}

				if (!loggedIn) {
					shouldShow = shouldShow && !!tool.isAvailableForFree;
				}

				return shouldShow;
			});
	}, [loggedIn, selectedCategory]);

	const renderTools = useMemo(() => {
		const renderedTools = toolsToShow.map((tool, index) => {
			const categoriesForTool = categories.filter(c => tool.categories.includes(c.id));
			const isLinkToFile = !!get(tool, 'linkToFile', 'url');

			return (
				<div key={tool.name || index} className="p-2 pb-3">
					<ConditionalWrap
						condition={isLinkToFile}
						when={children => (
							<a
								href={get(tool, 'linkToFile', 'url')}
								download={get(tool, 'linkToFile', 'raw').name}
								target="_blank"
								rel="noopener noreferrer">
								{children}
							</a>
						)}
						or={children => <Link to={tool.url}>{children}</Link>}>
						<div className="bg-white shadow-md rounded-lg p-6 relative">
							<div className="text-secondary text-lg font-medium font-display">
								{tool.name}
							</div>
							<p className="text-secondary-600 text-sm mt-1">{tool.description}</p>

							{isLinkToFile && (
								<div className="absolute right-0 bottom-0 mr-4 mb-4">
									<i className="bx bx-link-external block text-secondary-600"></i>
								</div>
							)}

							{categoriesForTool.map(c => (
								<span
									key={c.id}
									className="mr-2 mt-3 rounded-full bg-gray-400 text-xs inline-block p-1 px-3 text-blue-500 bg-blue-100 font-display font-medium">
									{c?.name}
								</span>
							))}
						</div>
					</ConditionalWrap>
				</div>
			);
		});

		renderedTools.push(
			<div key="help" className="p-2 pb-3">
				<div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between">
					<div>
						<div className="text-secondary text-lg font-medium font-display">
							{f(messages.needHelp)}
						</div>
						<p className="text-secondary-600 text-sm mt-1">{f(messages.cta)}</p>
					</div>
					<div className="mt-2">
						<a
							href="https://intensio.be/contact"
							rel="noopener noreferrer"
							target="_blank"
							className="bg-primary text-white inline-block font-medium rounded text-sm shadow-md py-2 px-4 md:text-sm cursor-pointer font-display ">
							{f(messages.contactUs)}
						</a>
					</div>
				</div>
			</div>
		);
		return renderedTools;
	}, [selectedCategory, loggedIn]);

	return (
		<div className="container mx-auto my-12 xl:my-16">
			<div className="flex flex-col lg:flex-row">
				{/* LEFT COLUMN */}
				<div className="flex-shrink-0 pb-4 pr-0 pl-0 lg:p-4 lg:pr-10 lg:-mx-3 lg:-mx- text-center sm:text-left">
					<button
						className="lg:hidden hover:bg-gray-100 text-primary py-2 px-2 rounded inline-flex items-center"
						onClick={() => setCategoryFilterOpen(!isCategoryFilterOpen)}>
						<i
							className={`bx bxs-chevron-${
								isCategoryFilterOpen ? 'right' : 'down'
							} mr-2`}></i>
						<span>{f(messages.filterCategory)}</span>
					</button>

					{isCategoryFilterOpen && (
						<div className="flex flex-col items-start pt-4 px-4 lg:pt-0 sm:px-0">
							<button
								className={cn('nav-pill block w-full', {
									active: !selectedCategory,
								})}
								onClick={() => {
									selectCategory(null);
									if (breakpoint === 'xs') {
										setCategoryFilterOpen(false);
									}
								}}>
								{f(messages.allTools)}
							</button>
							{categories.map(category => (
								<button
									key={category.id as string}
									onClick={() => {
										selectCategory(category.id);
										if (breakpoint === 'xs') {
											setCategoryFilterOpen(false);
										}
									}}
									className={cn('nav-pill block w-full', {
										active: selectedCategory === category.id,
									})}>
									{category.name}
								</button>
							))}
						</div>
					)}
				</div>

				{/* RIGHT COLUMN */}
				<div className="w-full bg-gray-200 rounded-lg lg:-mx-3 p-3">
					{renderTools.length === 1 && loggedIn ? (
						<div className="h-full flex justify-center items-center text-center">
							<div className="max-w-screen-sm text-gray-600">
								{f(messages.noToolsFound)}
							</div>
						</div>
					) : (
						<Masonry
							breakpointCols={{
								default: 2,
								768: 2,
								500: 1,
							}}
							className="flex"
							columnClassName="tools-grid-item">
							{renderTools}
						</Masonry>
					)}

					{!loggedIn && (
						<div className="relative">
							<div className="absolute z-10 top-0 left-0 right-0 full-w right-0 p-10 h-full flex items-center justify-center">
								<div className="max-w-xl text-secondary-600 text-center leading-relaxed">
									<FormattedMessage
										{...messages.freeTools}
										values={{
											amount: toolsToShow.length,
											strong: (...msg: any[]) => (
												<span className="font-extrabold">{msg}</span>
											),
											contactLink: (
												<a
													href="https://www.intensio.be/contact"
													className="text-primary underline"
													rel="noopener noreferrer"
													target="_blank">
													{f(messages.freeToolsContact)}
												</a>
											),
											loginLink: (
												<Link
													to="/auth/login"
													className="text-primary underline">
													{f(messages.freeToolsLogin)}
												</Link>
											),
										}}
									/>
								</div>
							</div>
							{/* DUMMY STUFF */}
							<div className="dummy flex flex-wrap relative z-0">
								<div className="w-full md:w-1/2 p-3">
									<div className="bg-white shadow-md rounded-lg p-6">
										<div className="text-secondary text-lg font-medium font-display">
											<span>Faciliteren</span>
										</div>
										<p className="text-secondary-600 text-sm mt-1">
											<span data-nosnippet>
												De rol van een procesbegeleider is om een
												(groeps)proces te faciliteren. Naast de
												communicatieve vaardigheden van de facilitator.
											</span>
										</p>
										<span className="mt-3 rounded-full bg-gray-400 text-xs inline-block p-1 px-3 text-blue-500 bg-blue-100 font-display font-medium">
											<span>Digitale tools om te faciliteren</span>
										</span>
									</div>
								</div>
								<div className="w-full md:w-1/2 p-3 hidden md:block">
									<div className="bg-white shadow-md rounded-lg p-6">
										<div className="text-secondary text-lg font-medium font-display">
											<span>Coaching</span>
										</div>
										<p className="text-secondary-600 text-sm mt-1">
											<span data-nosnippet>
												De rol van een procesbegeleider is om een
												(groeps)proces te faciliteren. Naast de
												communicatieve vaardigheden van de facilitator.
											</span>
										</p>
										<span className="mt-3 rounded-full bg-gray-400 text-xs inline-block p-1 px-3 text-blue-500 bg-blue-100 font-display font-medium">
											<span>Wat kan ik gebruiken om te faciliteren</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

// INTL

const messages = defineMessages({
	allTools: {
		id: 'tools.allTools',
		defaultMessage: 'Alle tools',
	},
	cta: {
		id: 'tools.cta',
		defaultMessage:
			'Op zoek naar een procesbegeleider voor een workshop, intervisie of verandertraject? Of hulp nodig bij een methodiek, draaiboek of architectuur van een workshop?',
	},
	contactUs: {
		id: 'tools.contactUs',
		defaultMessage: 'Contacteer ons',
	},
	needHelp: {
		id: 'tools.needHelp',
		defaultMessage: 'Hulp nodig?',
	},
	freeTools: {
		id: 'tools.freeTools',
		defaultMessage:
			'Deze {amount} tools stellen wij <strong>gratis</strong> ter beschikking. Indien je meer wilt weten en toegang wilt krijgen tot de rest van deze tools kan je ons {contactLink}. Indien je reeds toegang heeft, kan je gewoon {loginLink}.',
	},

	freeToolsContact: {
		id: 'tools.freToolsContact',
		defaultMessage: 'contacteren',
	},
	freeToolsLogin: {
		id: 'tools.freeToolsLogin',
		defaultMessage: 'inloggen',
	},
	filterCategory: {
		id: 'tools.filterCategory',
		defaultMessage: 'Filter per categorie',
	},
	noToolsFound: {
		id: 'tools.noToolsFound',
		defaultMessage:
			'We konden nog geen tools vinden voor deze categorie. We zijn hier waarschijnlijk nog mee bezig. Je kan best later eens terugkomen.',
	},
});
