import React, { FC, ReactNode, ReactElement } from 'react';

interface Props {
	condition: boolean;
	when: (children: ReactNode) => ReactElement;
	or?: (children: ReactNode) => ReactElement;
}

export const ConditionalWrap: FC<Props> = ({ condition, when, or, children }) => {
	if (condition) {
		return when(children);
	}

	return <>{or ? or(children) : children}</>;
};
