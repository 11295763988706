import { useRef, useEffect } from 'react';

export const useClickOutside = (open: boolean, onClickOutside: Function) => {
	const ref = useRef<HTMLDivElement>();

	const handleClickOutside = (e: any) => {
		if (ref.current?.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		onClickOutside();
	};

	useEffect(() => {
		if (open) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [open]);

	return { ref };
};
